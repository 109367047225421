import React from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery, Link } from 'gatsby';
import { Box, Grid, Typography, Button } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { pluck } from 'ramda';

import { items } from '../utils';
import ThumbnailItem from './ThumbnailItem';

const MoreMedia = ({ title, currentSermon }) => {
  const data = useStaticQuery(graphql`
    query {
      api {
        sermons(first: 5, orderBy: "-date,-created_at") {
          edges {
            node {
              id
              title
              slug
              speakers {
                edges {
                  node {
                    name
                    slug
                  }
                }
              }
              date
              youtubeId
            }
          }
        }
      }
    }
  `);

  const sermons = items(data.api.sermons);

  return (
    <>
      <Box mb={4}>
        <Typography variant="h4" component="h3">
          {title}
        </Typography>
      </Box>

      <Box mb={4}>
        <Grid container>
          {sermons
            .filter(({ id }) => !currentSermon || id !== currentSermon.id)
            .slice(0, 4)
            .map(
              ({ id, title: sermonTitle, slug, speakers, date, youtubeId }) => (
                <Grid item key={id} xs={12} sm={6}>
                  <ThumbnailItem
                    key={id}
                    title={sermonTitle}
                    subtext={pluck('name', items(speakers)).join(', ')}
                    timestamp={date}
                    image={`https://i.ytimg.com/vi/${youtubeId}/hqdefault.jpg`}
                    to={`/media/sermons/${slug}`}
                  />
                </Grid>
              )
            )}
        </Grid>
      </Box>

      <Button
        component={Link}
        to="/media"
        color="secondary"
        endIcon={<ChevronRightIcon />}
      >
        See all
      </Button>
    </>
  );
};

MoreMedia.propTypes = {
  title: PropTypes.string,
  currentSermon: PropTypes.object,
};

MoreMedia.defaultProps = {
  title: 'Recent Media',
  currentSermon: null,
};

export default MoreMedia;
